.forget-password_modal .forget-password-modal_title{
    display: flex;
    align-items: center;
    justify-content: center;
}
.forget-password_modal .forget-password-modal_title .logo{
    width: 48px;
}

.forget-password_modal .subtitle{
    font-size: 24px;
}
