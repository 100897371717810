.courseCatalog {
  .titleRow {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: var(--color-8);

    .collapseBtn {
      width: 16px;
      transition: transform 0.2s ease-in;

      &.collapse {
        transform: rotate(-180deg);
      }
    }
  }

  .sectionList {
    padding-top: 10px;
  }
}
