.navHeader {
  height: 60px;
  padding: 0 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  transition: width 3s ease;

  .logoArea {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .productName {
      font-weight: var(--font-weight-bold);
      color: var(--font-color-title);
      margin-left: 10px;
    }
  }

  .actionBtn {
    width: 16px;
    height: 16px;
    text-align: center;
    vertical-align: middle;
    padding: 3px;
    border-radius: 50%;
    border: 1px solid var(--font-color-main);

    &:hover {
      cursor: pointer;
    }
  }

  &.collapse {
    padding: 22px 0;
    width: 60px;
    flex-flow: column-reverse nowrap;
  }
}
