.courseCatalogList {
  height: 100%;
  display: flex;
  padding: 10px 20px;
  flex-flow: column nowrap;
  overflow-y: auto;

  .titleRow {
    flex: 0 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .titleArea {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .icon {
        width: 16px;
      }

      .titleName {
        margin-left: 12px;
      }
    }

    .chapterCount {
      font-size: var(--font-size-5);
      color: var(--color-8);
    }
  }

  .listRow {
    margin-top: 15px;
  }
}
