:global(#root) {
  display: block;
  width: 100%;
  height: 100%;
}

.newChatPage {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  font-size: var(--font-size-4-1);
  font-weight: var(--font-weight-4-1);
  color: var(--font-color-main);

  &.pc {
    display: block;
  }

  &.pad {
    display: block;
  }

  &.m {
    display: block;
  }
}
