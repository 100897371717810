.login_page {
  height: 100%;
  display: flex;
  justify-content: center;
}

.swiper_container {
  flex: 1;
  width: 60%;
  background-image: url("../../Assets/login-bg.png");
  background-size: cover;
}

.swiper_container .slider_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.swiper_container .slider_container img {
  margin-bottom: 20px;
}

.swiper_container .slider_container .title {
  font-size: 24px;
  line-height: 35px;
}
.swiper_container .slider_container .sub_title {
  color: #1d2129;
  font-size: 14px;
  opacity: 0.4;
  margin-top: 20px;
  padding: 0 24px;
  max-width: 610px;
}
.swiper-pagination-bullet-active {
  background: #ffffff !important;
}

.login_container {
  width: 40%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 60px;
}

.login_header {
  display: flex;
  align-items: center;
}

.login_header .logo {
  width: 28px;
}

.login_header .title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 22px;
  font-weight: bold;
}

.login-form_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form_container .login-form_header .title {
  font-size: 28px;
  margin-bottom: 32px;
  font-weight: bold;
}

.copyright {
  padding-bottom: 15px;
  margin: 0 -40px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  /* text-wrap: nowrap; */
  text-align: center;
}

.copyright a {
  font-size: 12px;
  margin-right: 8px;
}
