.settingWindow {
  display: block;
  padding: 10px;

  .settingRow {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }

    .rowIcon {
      width: 16px;
    }
  }

  .loginRow {
    .loginLeft {
      display: flex;
      align-items: center;
    }
  }
}
