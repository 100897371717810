.navDrawerWrapper {
  flex: 0 0 auto;

  .navDrawer {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
  }
}
