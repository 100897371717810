.user-avatar_container {
  display: flex;
}

.userInfo {
  display: flex;
  flex-direction: column;
}
.user-name {
  font-size: 14px;
  display: block;
  line-height: 1;
  margin-bottom: 6px;
}
.user-account-name {
  color: #888;
  font-size: 12px;
  display: block;
  line-height: 1;
}
