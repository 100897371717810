.chatApp {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f5f5f5;
}

.messageList {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;

  &.user {
    background-color: #e2f0ff;
    align-self: flex-end;
  }

  &.ai {
    background-color: #f0f0f0;
    align-self: flex-start;
  }

  .role {
    font-weight: bold;
    margin-right: 5px;
  }
}

.inputForm {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-top: 1px solid #ccc;
}

.inputField {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.submitButton {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
}
