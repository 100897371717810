.filingModal {
  font-size: var(--font-size-5);
  padding: 10px;
  line-height: 1.6em;


  :global(.ant-modal-content) {
    padding: 0;
  }

  .btnGroup {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    .actionBtn {
      padding: 0;
      font-size: var(--font-size-5);
      color: var(--color-2);
    }
  }
}
