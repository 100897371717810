.setting_page{
    background-color: #fff;
    height: 100%;
    border-radius: 8px;
    padding: 20px;
    overflow: auto;
}
.setting_page .user-name{
    font-size: 28px;
    color: #020202;
}

.setting_page .user-email{
    color: #7f7f7f;
}

.info_card{
    margin-bottom: 16px;
}

.info_card .ant-card-body{
    height: 60px;
    box-sizing: content-box;
    font-size: 14px;
    color: #777;
}
